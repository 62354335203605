body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rbc-calendar .rbc-month-view .rbc-month-row,
.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content,
.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-date-cell
{
flex: 1;
}

.rbc-calendar { min-height: 500px; }
